var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EKTable',{attrs:{"items":_vm.orders,"columns":_vm.columns,"selectedLabel":"id","rowStyle":_vm.rowStyle},on:{"details":_vm.openOrdersDetails,"delete-selected":_vm.fireDeleteEvent},scopedSlots:_vm._u([{key:"items.totalPrice",fn:function(ref){
var props = ref.props;
return [_vm._v(" "+_vm._s(props.row.currency == 1 ? _vm.t("usd") : _vm.t("sp"))+" "+_vm._s(_vm._f("numFormat")(props.row.totalPrice))+" ")]}},{key:"items.address",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value == null ? "-" : value)+" ")]}},{key:"items.mobileNumber",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value == null ? "-" : value)+" ")]}},{key:"items.email",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value == null ? "-" : value)+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }