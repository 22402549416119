<template>
    <EKTable
        :items="orders"
        :columns="columns"
        selectedLabel="id"
        :rowStyle="rowStyle"
        @details="openOrdersDetails"
        @delete-selected="fireDeleteEvent"
    >
        <template slot="items.totalPrice" slot-scope="{ props }">
            {{ props.row.currency == 1 ? t("usd") : t("sp") }}

            {{ props.row.totalPrice | numFormat }}
        </template>
        <template slot="items.address" slot-scope="{ value }">
            {{ value == null ? "-" : value }}
        </template>
        <template slot="items.mobileNumber" slot-scope="{ value }">
            {{ value == null ? "-" : value }}
        </template>
        <template slot="items.email" slot-scope="{ value }">
            {{ value == null ? "-" : value }}
        </template>
    </EKTable>
</template>
<style>
.isFinished {
    background-color: #6cae824d;
}
</style>
<script>
import { useUtils as useI18nUtils } from "@core/libs/i18n";

import EKTable from "@Ekcore/components/EK-table";
import { mapActions, mapState } from "vuex";
export default {
    setup() {
        const { t } = useI18nUtils();
        return { t };
    },
    components: {
        EKTable
    },
    data: () => ({
        columns: [
            {
                label: "رقم الطلب",
                field: "number",
                sortable: true
            },
            {
                label: "المرسل",
                field: "fullName"
            },
            {
                label: "العنوان",
                field: "address"
            },
            {
                label: "رقم الجوال",
                field: "mobileNumber"
            },
            {
                label: "الايميل",
                field: "email"
            },
            {
                label: "السعر الإجمالي للطلبية",
                field: "totalPrice",
                type: "number",
                thClass: "vgt-left-align sortable",
                tdClass: "vgt-left-align"
            },
            {
                label: "تفاصيل",
                field: "details",
                sortable: false
            }
        ]
    }),
    created() {
        this.fetchOrders();
    },
    computed: {
        ...mapState({
            orders: state => state.order.orders
        })
    },
    methods: {
        rowStyle(row) {
            return row.dateFinished ? "isFinished" : "";
        },
        ...mapActions(["fetchOrders", "deleteMultiOrders"]),
        openOrdersDetails(props) {
            this.$router.push("/admin/orders/" + props.row.id);
        },
        fireDeleteEvent(list) {
            this.deleteMultiOrders(list);
        },
        beforeDestroy() {
            this.$store.commit("Reset_Search_Dto");
        }
    }
};
</script>
